import * as React from "react";
import { Box } from "../components/atoms/box";
import { StudioAnimatedScroller } from "../components/templates/animation/AnimatedScroller";
import { Page } from "../components/templates/page/page";
import { StudioArchitectureSection } from "../sections/studio/studio-architecture.section";
import { StudioCollaborators } from "../sections/studio/studio-collaborators";
import { StudioIntroSection } from "../sections/studio/studio-into.section";

const StudioPage = () => (
  <Page title={"H2FA Design Lab"}>
    <AnimationWrapper />
    <StudioIntroSection />
    <StudioArchitectureSection />
    <StudioCollaborators />
  </Page>
);

const AnimationWrapper = () => (
  <Box height={"100%"} position={"absolute"} width={"100%"}>
    <Box width={"100%"} position={"absolute"} top={{ sm: 150, lg: 150 }}>
      <Line0 />
    </Box>
    <Box width={"100%"} position={"absolute"} top={{ sm: 813, lg: 855 }}>
      <Line1 />
    </Box>
    <Box width={"100%"} position={"absolute"} top={{ sm: 1630, lg: 1675 }}>
      <Line2 />
    </Box>
    <Box width={"100%"} position={"absolute"} top={{ sm: 2650, lg: 2650 }}>
      <Line3 />
    </Box>
    <Box width={"100%"} position={"absolute"} top={4855}>
      <Line4 />
    </Box>
    <Box
      display={{ sm: "none", lg: "block" }}
      maxW={"lg"}
      m={"0 auto"}
      width={"100%"}
      height={"100%"}
      position={"relative"}
    >
      <Box height={"100%"} position={"absolute"} right={{ sm: 44, lg: 48 }}>
        <StudioAnimatedScroller />
      </Box>
    </Box>
  </Box>
);

const Line0 = () => (
  <svg
    style={{
      width: "100%",
      minHeight: 1,
      opacity: "0.5",
    }}
    fill="none"
    viewBox={"0 0 1440 1"}
  >
    <path stroke="url(#line-studio-0)" d="M0 .5h1440" />
    <defs>
      <linearGradient id="line-studio-0" x1={1440} y1={1} x2={38.126} y2={1} gradientUnits="userSpaceOnUse">
        <stop stopColor="#7AA2E8" />
        <stop offset={0.734} stopColor="#C1C9C3" />
      </linearGradient>
    </defs>
  </svg>
);

const Line1 = () => (
  <svg
    style={{
      width: "100%",
      minHeight: 1,
      opacity: "0.5",
    }}
    fill="none"
    viewBox={"0 0 1440 1"}
  >
    <path stroke="url(#line-studio-1)" d="M0 .5h1440" />
    <defs>
      <linearGradient id="line-studio-1" x1={1440} y1={1} x2={38.126} y2={1} gradientUnits="userSpaceOnUse">
        <stop stopColor="#7AA2E8" />
        <stop offset={0.734} stopColor="#C1C9C3" />
      </linearGradient>
    </defs>
  </svg>
);

const Line2 = () => (
  <svg
    style={{
      width: "100%",
      minHeight: 1,
      opacity: "0.5",
    }}
    fill="none"
    viewBox={"0 0 1440 1"}
  >
    <path stroke="url(#line-studio-2)" d="M0 .5h1440" />
    <defs>
      <linearGradient id="line-studio-2" x1={1545.58} y1={0.999} x2={38.126} y2={1} gradientUnits="userSpaceOnUse">
        <stop stopColor="#95A8EB" />
        <stop offset={1} stopColor="#4C82E3" />
      </linearGradient>
    </defs>
  </svg>
);

const Line3 = () => (
  <svg
    style={{
      width: "100%",
      minHeight: 1,
      opacity: "0.5",
    }}
    fill="none"
    viewBox={"0 0 1440 1"}
  >
    <path stroke="url(#line-studio-3)" d="M0 .5h1440" />
    <defs>
      <linearGradient id="line-studio-3" x1={1545.58} y1={0.999} x2={38.126} y2={1} gradientUnits="userSpaceOnUse">
        <stop stopColor="#06F0FF" />
        <stop offset={0.521} stopColor="#2CA5C4" />
        <stop offset={1} stopColor="#DFF1F4" />
      </linearGradient>
    </defs>
  </svg>
);

const Line4 = () => (
  <svg
    style={{
      width: "100%",
      minHeight: 1,
      opacity: "0.5",
    }}
    fill="none"
    viewBox={"0 0 1402 1"}
  >
    <path stroke="url(#line-studio-4)" d="M0 .5h1435" />
    <defs>
      <linearGradient id="line-studio-4" x1={1540.21} y1={0.999} x2={37.994} y2={1} gradientUnits="userSpaceOnUse">
        <stop stopColor="#06F0FF" />
        <stop offset={0.521} stopColor="#2CA5C4" />
        <stop offset={1} stopColor="#DFF1F4" />
      </linearGradient>
    </defs>
  </svg>
);

export default StudioPage;
