import { useThrottledFn, useWindowScroll } from "beautiful-react-hooks";
import React from "react";
import styled from "styled-components";
// @ts-ignore
import iconCircle from "../../../assets/images/icons/studio-circle.png";
// @ts-ignore
import iconSquare from "../../../assets/images/icons/studio-square.png";
// @ts-ignore
import iconTriangle from "../../../assets/images/icons/studio-triangle.png";
import { Box } from "../../atoms/box";
import { Theme } from "../../core/app.theme";

export const isBrowser = typeof window !== `undefined`;

export const StudioAnimatedScroller = () => {
  const [scrollY, setScrollY] = React.useState(isBrowser ? window.scrollY : 0);

  useWindowScroll(
    useThrottledFn(
      () => {
        setScrollY(isBrowser ? window.scrollY : 0);
      },
      10,
      {},
      []
    )
  );

  const calculateNewPositions = () => {
    const initial = 125;
    const squareInitial = initial + 700;
    const circleInitial = initial + 1520;
    return {
      triangle: Math.min(initial + scrollY, 740),
      square: scrollY >= 700 ? Math.min(initial + scrollY, 1540) : squareInitial,
      circle: scrollY >= 1520 ? Math.min(initial + scrollY, 6000) : circleInitial,
    };
  };

  return (
    <Box position={"relative"} h={"100%"}>
      <LineBackground />
      <IconWrapper top={calculateNewPositions().triangle}>
        <IconImage src={iconTriangle} />
      </IconWrapper>
      <IconWrapper top={calculateNewPositions().square}>
        <IconImage src={iconSquare} />
      </IconWrapper>
      <IconWrapper top={calculateNewPositions().circle}>
        <IconImage src={iconCircle} />
      </IconWrapper>
    </Box>
  );
};

const LineBackground = styled.div({
  height: "100%",
  width: "1px",
  opacity: "0.5",
  background: "linear-gradient(90deg, #7FB7CD 20%, #7CA3BD 40%, #B7D0E4 55%, #728FCB 80%, #7191ED 100%)",
});

const IconWrapper = styled.div.attrs<{ top: number }>(({ top }) => ({
  style: { "--top": `${top}px` },
}))<{ top: number }>({
  position: "absolute",
  zIndex: 999,
  left: -24,
  top: "var(--top)",
  [Theme.media("lg")]: {
    left: -40,
  },
});

const IconImage = styled.img.attrs({ alt: "" })({
  height: 50,
  width: 50,
  [Theme.media("lg")]: {
    height: 80,
    width: 80,
  },
});
