import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Flex } from "../../components/atoms/box";
import { Text } from "../../components/atoms/typography";
import { Responsive } from "../../components/gls/responsive";
import { VerticalSpacerResponsive } from "../../components/gls/spacer";
import { Vertical } from "../../components/gls/vertical";
import { Section } from "../../components/templates/page/section/section";
import { StudioAccordion, StudioSectionText } from "./studio-components";
import { SunRotation } from "./studio-decorative";

export const StudioIntroSection = () => (
  <Background>
    <IntroSection />
    <VerticalSpacerResponsive space={{ sm: "xl", lg: 400 }} />
    <HStationDesignSection />
  </Background>
);
const IntroSection = () => (
  <Section seamless maxWidth={1000} pt={"4xl"}>
    <Text
      fontFamily={"libre"}
      fontSize={{ sm: "4xl", lg: "5xl" }}
      lineHeight={"shorter"}
      textAlign={"left"}
      mb={"xl"}
      fontWeight={"bold"}
    >
      You’re Inside the H2FA Design Lab
    </Text>
    <Responsive>
      <Flex flex={1}>
        <Text fontSize={"lg"} colorScheme={"white"} fontWeight={"light"} fontStyle={"italic"} maxWidth={450}>
          A collaborative space that’s at the forefront of context sensitive design with a future forward vision.
        </Text>
      </Flex>
      <Flex flex={1}>
        <Text fontSize={"sm"} maxWidth={450}>
          At Hydrogen Fuels Australia, our plans include off-grid green hydrogen production that runs in #IslandMode,
          combined with distribution to our own fuel stations, creating more resilient communities and a synergy with
          nature. While hydrogen technology and operations are our focus, the user experience at our stations aims to
          set us apart from competitors. Learn more about our design team, design awards and ongoing projects below.
        </Text>
      </Flex>
    </Responsive>
  </Section>
);

const HStationDesignSection = () => (
  <Section seamless position={"relative"}>
    <Box display={{ sm: "none", lg: "block" }} position={"absolute"} top={-350} left={300}>
      <SunRotation />
    </Box>
    <Vertical horizontalAlign={"left"} maxWidth={450}>
      <StudioSectionText maxW={100}>H_Station Design</StudioSectionText>
      <Box display={{ sm: "flex", lg: "none" }} justifyContent={"center"} width={"100%"}>
        <SunRotation />
      </Box>
      <StaticImage
        src={`../../assets/images/infrastructure/h_design_station.png`}
        placeholder="blurred"
        objectFit={"fill"}
        height={250}
        alt=""
      />
      <Text mb={"sm"}>
        H_Station signifies a grand step in a global initiative toward a sustainable future. In an era of extreme
        technological progress and environmental degradation, H_Station provides hope that a balance between the
        synthetic and natural world can be achieved.
      </Text>
      <StudioAccordion>
        <Vertical>
          <Text>
            The world’s cities are today responsible for an estimated 75% of global CO2 emissions. This density is the
            main cause of global warming with transport and buildings being among the largest contributors. Green
            hydrogen fuel offers an environmentally conscious and revolutionary alternative, with zero emissions from
            the tailpipe and close to zero in its green stream production.
          </Text>
          <Text>
            By turning a new leaf, we are presented with advantages which provide more scope and flexibility for on-site
            design. H_Station re-imagines the task of re-fuelling, the activities on-site and how place, preservation
            and cultural context can be considered. This is all possible because the harmful fumes and excessive use of
            concrete are no longer necessary characteristics of a refuelling station.
          </Text>
          <Text>
            The cities we build are sprawling - both horizontally and vertically - at an unprecedented pace, most
            without architectural consideration. Our cities however are beacons of progress and hubs for human
            evolution, and will therefore will continue to grow. The question of ‘How Will We Live Together?’ is not one
            an architect can answer alone, it is a question to be answered in collaboration with all kinds of
            disciplines, ensuring a circular attitude is developed.
          </Text>
          <Text>
            In this belief, H_Station utilises modular construction with a light footprint on the land it resides on,
            that can be easily and delicately relocated; preserving natural ecosystems. It is also a contextually and
            culturally sensitive design, celebrating its locality. The concept is a circular model of green hydrogen
            production.. Green hydrogen by-products are oxygen and heat, both resources that will be reused on site with
            greenhouses, vertical farms and freshwater fish. The renovation of existing buildings not only preserves the
            local character, but the space provides opportunity for community activity, making use of the function
            centre, small museum and seated café to draw in the public.
          </Text>
        </Vertical>
      </StudioAccordion>
    </Vertical>
  </Section>
);

const Background = styled.div({
  background: "linear-gradient(171.24deg, #A7B3F3 6.56%, rgba(45, 101, 230, 0.85) 30.93%, #4D65DC 87.54%)",
});
