import * as React from "react";
import { TransitionEaseIn } from "../../components/atoms/animation";
import { Link, LinkProps } from "../../components/atoms/link";
import { Chevron } from "../../components/atoms/others";
import { Text, TypographyProps } from "../../components/atoms/typography";
import { Theme } from "../../components/core/app.theme";
import { Horizontal } from "../../components/gls/horizontal";
import { VerticalSpacer } from "../../components/gls/spacer";

export const StudioSectionText = (props: TypographyProps) => (
  <Text fontFamily={"monument"} colorScheme={"white"} fontSize={"2xl"} fontWeight={"book"} {...props} />
);

export const StudioExternalLink = (props: LinkProps) => (
  <Link fontSize={"xs"} target={"_blank"} textTransform={"uppercase"} underline={true} {...(props as any)} />
);

export const StudioTextLink = (props: LinkProps) => (
  <Link fontWeight={"normal"} target={"_blank"} underline={true} {...(props as any)} />
);

export const StudioAccordion = (props: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div>
      <Horizontal space={2}>
        <Link
          as={"button"}
          fontSize={"xs"}
          textTransform={"uppercase"}
          underline={true}
          onClick={() => setIsOpen(!isOpen)}
        >
          Read More
        </Link>
        <Chevron color={Theme.color("icywaters")} open={isOpen} />
      </Horizontal>
      <VerticalSpacer />
      <TransitionEaseIn when={isOpen}>{props.children}</TransitionEaseIn>
    </div>
  );
};
