import * as React from "react";
import { Box, BoxProps } from "./box";

export const YoutubePlayer = (props: { youtubeId: string } & BoxProps) => (
  <Box {...props}>
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}
    >
      <iframe
        title={"youtube"}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={`https://www.youtube.com/embed/${props.youtubeId}`}
        frameBorder="0"
      />
    </div>
  </Box>
);
