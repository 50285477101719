import * as React from "react";
import { useRef } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
// @ts-ignore
import iconCircle from "../../assets/images/icons/studio-circle-drops.png";
import { Box } from "../../components/atoms/box";
import { Theme } from "../../components/core/app.theme";

export const SunRotation = () => {
  const { ref, inView } = useInView({
    threshold: [0.25],
    delay: 1000,
  });
  return (
    <WrapperRotatedSvg
      ref={ref}
      viewBox="0 0 621 623"
      style={{
        width: "max(100%,521px)",
        height: "auto",
        animation: inView ? `rotation-1633331131 12s infinite linear` : undefined,
      }}
    >
      <path
        d="M1 323.081v-23.277h204.699v23.277H1zM84.283 98.965l16.468-16.46 144.744 144.667-16.468 16.459L84.283 98.965zM300.081 1h23.289v204.588h-23.289V1zm221.901 83.238l16.468 16.459-144.744 144.666-16.468-16.459L521.982 84.238zM620 299.92v23.276H415.301V299.92H620zm-83.283 221.781l-16.468 16.459-144.744-144.666 16.468-16.459 144.744 144.666zM320.919 622H297.63V417.412h23.289V622zM99.018 538.762L82.55 522.303l144.744-144.666 16.468 16.459L99.018 538.762z"
        stroke="white"
        fill={"transparent"}
        strokeWidth={0.727}
        strokeMiterlimit={1}
      />
    </WrapperRotatedSvg>
  );
};

export const CircularDrops = ({
  size = "lg",
  container,
}: {
  size: "sm" | "lg";
  container: { height: number; width: number };
}) => {
  const { ref, inView } = useInView({
    threshold: [0.25],
    delay: 1000,
  });
  const { wrapper, img } = {
    sm: { wrapper: "sm", img: 75 },
    lg: { wrapper: "lg", img: 175 },
  }[size];
  return (
    <Box position={"relative"} ref={ref} height={container.height} width={container.width}>
      <CircleDropWrapper padding={wrapper}>
        <img alt={""} height={img} width={img} src={iconCircle} />
      </CircleDropWrapper>
      <CircleDropWrapper padding={wrapper} translateAnimation={inView ? "translateY(75%)" : "none"} delay={"350ms"}>
        <img alt={""} height={img} width={img} src={iconCircle} />
      </CircleDropWrapper>
      <CircleDropWrapper padding={wrapper} translateAnimation={inView ? "translateY(150%)" : "none"} delay={"250ms"}>
        <img alt={""} height={img} width={img} src={iconCircle} />
      </CircleDropWrapper>
    </Box>
  );
};

const CircleDropWrapper = styled.div<{ padding: Theme.Space; translateAnimation?: string; delay?: string }>(
  (props) => ({
    position: "absolute",
    padding: Theme.space(props.padding),
    borderRadius: Theme.radius("full"),
    border: `1px solid ${Theme.color("icywaters")}`,
    transform: props.translateAnimation,
    transition: "transform 250ms",
    transitionDelay: props.delay,
  })
);

const WrapperRotatedSvg = styled.svg({
  [`@keyframes rotation-1633331131`]: {
    "0%": {
      transform: "rotate(0turn)",
    },
    "12.5%": {
      transform: "rotate(90deg)",
    },
    "25%": {
      transform: "rotate(-180deg)",
    },
    "37.5%": {
      transform: "rotate(-60deg)",
    },
    "50%": {
      transform: "rotate(-420deg)",
    },
    "62.5%": {
      transform: "rotate(-360deg)",
    },
    "75%": {
      transform: "rotate(-360deg)",
    },
    "100%": {
      transform: "rotate(-360deg)",
    },
  },
});

export const WaterFilled = () => {
  const { ref, inView } = useInView({
    threshold: [1],
    delay: 500,
  });
  const animateStartRef = useRef<SVGElement & { beginElement: () => void }>(null);
  const animateEndRef = useRef<SVGElement & { beginElement: () => void }>(null);

  React.useEffect(() => {
    if (inView) {
      animateStartRef.current?.beginElement();
      animateEndRef.current?.beginElement();
    }
  }, [inView]);

  return (
    <div ref={ref}>
      <svg
        style={{
          width: "min(100%,650px)",
          height: "auto",
        }}
        viewBox="0 0 819 796"
        fill="none"
      >
        <path
          d="M547.448 794.058s102.587-109.226 82.472-279.431C615.575 393.249 695.629 270.712 819 250.603L556.383.293C477.966 26.363 336.636 93.427 290.32 246.68a355.334 355.334 0 00-13.94 75.493C245.018 725.724 0 795.293 0 795.293l547.448-1.235z"
          fill="url(#water-fill-gradient)"
          fillOpacity={0.5}
          stroke={"black"}
          strokeWidth={"1px"}
        />
        <defs>
          <linearGradient id="water-fill-gradient" x1={4} y1={795} x2={734} y2={143} gradientUnits="userSpaceOnUse">
            <stop offset={1} stopColor="transparent">
              <animate
                ref={animateStartRef}
                begin="indefinite"
                dur="2s"
                attributeName="offset"
                fill="freeze"
                from="1"
                to="0"
              />
            </stop>
            <stop offset={1} stopColor="#5FB4F0">
              <animate
                ref={animateEndRef}
                begin="indefinite"
                dur="2s"
                attributeName="offset"
                fill="freeze"
                from="1"
                to="0"
              />
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export const WaterDrops = () => {
  const { ref, inView } = useInView({
    threshold: [0],
    delay: 0,
  });
  const animateStartRef = useRef<SVGElement & { beginElement: () => void }>(null);

  React.useEffect(() => {
    if (inView) {
      animateStartRef.current?.beginElement();
    }
  }, [inView]);
  return (
    <WaterDropsWrapper ref={ref} fill="none" viewBox="0 0 348 1036">
      <animate attributeName="opacity" ref={animateStartRef} values="0;1" dur="3s" />
      <path
        d="M169.573 1036h8.854C272.08 1036 348 949.007 348 841.696V194.305C348 86.993 272.08 0 178.428 0h-8.855C75.92 0 0 86.993 0 194.304v647.392C0 949.007 75.92 1036 169.573 1036z"
        fill="url(#water-drops-gradient)"
      />
      <defs>
        <linearGradient
          id="water-drops-gradient"
          x1={174.328}
          y1={100.613}
          x2={173.508}
          y2={895.721}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#39A3C5" />
          <stop offset={1} stopColor="#2F82D5" stopOpacity={0.1} />
        </linearGradient>
      </defs>
    </WaterDropsWrapper>
  );
};

const WaterDropsWrapper = styled.svg({
  width: "min(148px,100%)",
  height: "auto",
  [Theme.media("lg")]: {
    width: 348,
  },
});
