import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Flex, Grid, Position } from "../../components/atoms/box";
import { Text } from "../../components/atoms/typography";
import { YoutubePlayer } from "../../components/atoms/youtube-player";
import { Horizontal } from "../../components/gls/horizontal";
import { Responsive } from "../../components/gls/responsive";
import { DesktopOnly, MobileOnly, ResponsiveLayout } from "../../components/gls/responsive-layout";
import { StretchSpacer, VerticalSpacer } from "../../components/gls/spacer";
import { Vertical } from "../../components/gls/vertical";
import { Section } from "../../components/templates/page/section/section";
import { StudioAccordion, StudioExternalLink, StudioSectionText, StudioTextLink } from "./studio-components";
import { CircularDrops, WaterFilled } from "./studio-decorative";

export const StudioArchitectureSection = () => (
  <Background>
    <StudioSarahCeravoloQuoteSection />
    <VerticalSpacer space={"3xl"} />
    <Position isolation={"isolate"}>
      <ArchitectureSection />
      <Box display={{ sm: "none", lg: "grid" }} placeItems={"center"} position={"relative"} zIndex={1} mt={-30}>
        <CircularDrops size={"lg"} container={{ height: 450, width: 200 }} />
      </Box>
      <PumpDesignSection />
    </Position>
  </Background>
);

const StudioSarahCeravoloQuoteSection = () => (
  <Section>
    <Grid gridRowGap={"lg"}>
      <Box display={"flex"} gridArea={"1/1"} position={"relative"} alignItems={"center"} justifyContent={"center"}>
        <Quote />
      </Box>
      <Grid gridArea={{ lg: "1/1" }}>
        <WaterFilled />
      </Grid>
    </Grid>
  </Section>
);

const ArchitectureSection = () => (
  <Section seamless position={"relative"} zIndex={2}>
    <ResponsiveLayout
      mobile={() => (
        <Vertical space={"xl"}>
          <VeniceOfArchitecture />
          <YoutubePlayer youtubeId={"rOAa-yIsrN8"} />
          <DesignFilesFinalist />
        </Vertical>
      )}
      desktop={() => (
        <Vertical space={"2xl"}>
          <Horizontal space={"3xl"}>
            <VeniceOfArchitecture />
            <DesignFilesFinalist />
          </Horizontal>
          <YoutubePlayer youtubeId={"rOAa-yIsrN8"} />
        </Vertical>
      )}
    />
  </Section>
);

const VeniceOfArchitecture = () => (
  <Vertical space={0}>
    <StudioSectionText maxWidth={400} lineHeight={"shorter"} mb={{ sm: "lg", lg: "2xl" }} overflowWrap={"break-word"}>
      The 17th Venice Biennale of Architecture
    </StudioSectionText>
    <Vertical maxWidth={400}>
      <Text>
        We are honoured that our{" "}
        <StudioTextLink href={"https://www.hscaled.com/project-3"}>H2FA H_Station project</StudioTextLink> by{" "}
        <StudioTextLink href={"https://hscaled.com"}>H_Scaled </StudioTextLink>
        was invited to this year’s{" "}
        <StudioTextLink href={"https://www.labiennale.org/en/architecture/2021"}>
          17th Venice Architecture Biennale, 2021 with the theme: “How Will We Live Together?”
        </StudioTextLink>{" "}
        This year’s theme perfectly describes the main mission of the H2FA Design Team in helping to create resilient
        communities.
      </Text>
      <StudioExternalLink href={"https://cityxvenice.org/"}>Explore City X Venice</StudioExternalLink>
      <ManWithDispenserImage />
    </Vertical>
  </Vertical>
);

const DesignFilesFinalist = () => (
  <Box maxWidth={400}>
    <DesktopOnly>
      <DesignFiles />
      <VerticalSpacer />
    </DesktopOnly>
    <StudioSectionText lineHeight={"shorter"} mb={{ sm: "lg", lg: "2xl" }} overflowWrap={"break-word"}>
      The Design Files Finalist
    </StudioSectionText>
    <Vertical maxWidth={450}>
      <Text mb={"md"}>
        We are pleased that the H_Station design has been shortlisted in The Design Files + Laminex Australia Design
        Awards, a program designed to acknowledge and recognise the diverse creative fields which make up modern
        Australia. We look forward to sharing more details on the innovative and imaginative industrial design vision
        for our first location.
      </Text>
      <StudioExternalLink href={"https://www.tdfdesignawards.com/category/sustainable-design"}>
        Learn More
      </StudioExternalLink>
    </Vertical>
    <MobileOnly>
      <VerticalSpacer />
      <DesignFiles />
    </MobileOnly>
  </Box>
);

const PumpDesignSection = () => (
  <Section maxWidth={1000} position={"relative"} zIndex={2}>
    <Responsive horizontal={{ space: "xl" }} vertical={{ space: "xl" }}>
      <DesktopOnly>
        <PumpImage minWidth={300} />
      </DesktopOnly>
      <Vertical maxWidth={500} space={0}>
        <StudioSectionText maxWidth={{ lg: 200 }} mb={{ sm: "lg" }}>
          H2FA Bimini Dispenser
        </StudioSectionText>
        <StretchSpacer />
        <Text mb={"sm"}>
          Traditional refuelling stations are notorious for their fume and concrete characteristics. Fossil fuels don't
          allow for many other alternatives. Remaining on-site for more time than necessary is undesirable for users.
          Most people wouldn’t want to take in the surroundings or even take a deep breath at a typical petrol station.
          Fuel dispensers offer little else than a few advertisements and a total utilitarian experience. It seems that
          design development toward this aspect has not yet evolved. We’ve set out to change this.
        </Text>
        <StudioAccordion>
          <Text>
            The Bimini Island dispenser is a modular unit which sits as its own destination among the flow of vehicles
            and people. Its large transparent screen integrates with its surroundings and acts as a window to the
            environment beyond. This allows views to the landscape when seen from afar, and with use. The Bimini Island
            dispenser gives users the unique ability to see beyond its immediate structure. Its fluid and organic form
            mimics the laws of nature, with no right angles or obvious joins.
          </Text>
        </StudioAccordion>
      </Vertical>
      <MobileOnly>
        <Flex justifyContent={"flex-end"}>
          <CircularDrops size={"sm"} container={{ height: 75, width: 105 }} />
        </Flex>
        <PumpImage width={"75%"} />
      </MobileOnly>
    </Responsive>
  </Section>
);

const Quote = () => (
  <Vertical>
    <Text
      maxWidth={680}
      fontSize={{ sm: "md", lg: "lg" }}
      colorScheme={"white"}
      fontWeight={"light"}
      fontStyle={"italic"}
    >
      <q>
        Human existence must return as a partner of the natural environment's ecological system. For this to be
        possible, technological progress must continue on the basis of developing synergies with nature. Design can help
        to humanise a place - this synergy creates an environment that bridges the gap for a better user experience.
      </q>
    </Text>
    <Text fontSize={{ sm: "sm", lg: "md" }} colorScheme={"white"} fontWeight={"bold"}>
      Sarah Ceravolo, H2FA Chief Creative Officer (CCO)
    </Text>
  </Vertical>
);

const DesignFiles = () => (
  <StaticImage src={`../../assets/images/infrastructure/design-files.png`} placeholder="blurred" alt="" />
);

const PumpImage = ({ minWidth, width }: { minWidth?: number; width?: string }) => (
  <StaticImage
    style={{ filter: "grayscale(100%)", minWidth: minWidth, width }}
    src={`../../assets/images/infrastructure/pump-design.png`}
    placeholder="blurred"
    alt=""
  />
);

const ManWithDispenserImage = () => (
  <StaticImage
    style={{ filter: "grayscale(100%)" }}
    src={`../../assets/images/infrastructure/man-with-dispenser.png`}
    placeholder="blurred"
    alt=""
  />
);

const Background = styled.div({
  background: "linear-gradient(175.37deg, #4D65DC 5.12%, #2AA7B8 34.49%, #2BA5BA 55.22%, #4C82E3 95.03%)",
});
