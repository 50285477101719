import * as React from "react";
import { IconChevronDown } from "./icon";

export const Chevron = ({ open = false, size = 24, color }: { open?: boolean; size?: number; color?: string }) => (
  <div
    style={{
      display: "block",
      transition: "transform 0.2s ease-out",
      transform: `${open ? "rotate(-90deg)" : "rotate(0deg)"}`,
    }}
  >
    <IconChevronDown size={size} color={color} />
  </div>
);

export const ShiftBy = ({ x = 0, y = 0, children }: { x?: number; y?: number; children: React.ReactNode }) => (
  <div
    style={{
      transform: `translate(${x}px, ${y}px)`,
    }}
  >
    {children}
  </div>
);
